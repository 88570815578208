import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '../components/layout'
import AboutCard from '../components/about-card'
import FeaturedPosts from '../components/featured-posts'

type IndexPageProps = PageProps | Record<string, never>

export default function IndexPage({ location }: IndexPageProps): JSX.Element {
  const protingent = <a href="https://www.protingent.com">Protingent</a>
  const stryker = (
    <a href="https://strykeremergencycare.com">Stryker Emergency Care</a>
  )
  const visitLinkedIn = (
    <a href="https://www.linkedin.com/in/carl-b-smiley">visit my LinkedIn</a>
  )
  return (
    <Layout location={location}>
      <section className="section" id="about-me">
        <div className="columns container">
          <div className="column is-one-quarter">
            <AboutCard />
          </div>
          <div className="column">
            <article className="article content">
              <h1 className="title">About Me</h1>
              <p>
                I am an embedded systems engineer with practical experience in
                both hardware and software.
              </p>
              <p>
                I currently work at {protingent} as a embedded systems engineer
                for {stryker}.
              </p>
              <p>I am located in the Seattle metropolitan area.</p>
              <p>For professional inquiries, please {visitLinkedIn}.</p>
            </article>
          </div>
        </div>
      </section>
      <section className="section" id="projects">
        <article className="article content">
          <h1 className="title">Projects and Things</h1>
          <FeaturedPosts />
        </article>
      </section>
    </Layout>
  )
}
