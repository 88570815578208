import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'

type MarkdownRemark = {
  node: {
    id: string
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      description: string
    }
  }
}

export default function FeaturedPosts(): JSX.Element {
  const data = useStaticQuery(graphql`
    query FEATURED_POST_QUERY {
      allMarkdownRemark {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
            }
          }
        }
      }
    }
  `)

  const posts: [MarkdownRemark] = data?.allMarkdownRemark?.edges

  return (
    <div className="columns is-multiline">
      {posts &&
        posts.map(({ node: post }) => (
          <div className="column is-full" key={post.id}>
            <article className="media">
              <figure className="media-left">
                <div className="image is-64x64">
                  <img src="https://bulma.io/images/placeholders/128x128.png" />
                </div>
              </figure>
              <div className="media-content">
                <div className="content">
                  <p>
                    <Link
                      className="title is-size-5 has-text-primary"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <br />
                    {post.frontmatter.description}
                    <br />
                  </p>
                </div>
              </div>
            </article>
          </div>
        ))}
    </div>
  )
}
